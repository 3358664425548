import { Injectable } from '@angular/core';
import { BaseService } from '../../../shared/base/base.service';
import { Constants, SearchOperations } from '../../../app.constants';
import { HttpRequestType, RequestService } from 'src/app/core/request.service';
import { AlertService } from 'src/app/core/alert.service';
import CustomStore from 'devextreme/data/custom_store';
import { LookupSource, LS } from 'src/app/shared/base/base.models';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {

  protected totalCount: number;

  constructor(protected requestService: RequestService, private alertService: AlertService) {
    super(requestService);
  }

  public getUsersOptions() {
    return this.getOptionsByMultipleFieldsFilter(`${Constants.contextPath}/users`, 'name', ['name', 'login', 'email'], (response) => {
      if(Array.isArray(response))
        for(let u of response)
          u.name = `${u.name}, ${u.email}`;
      else 
        response.name = `${response.name}, ${response.email}`;
      
      return response;
    })
  }

  public getUsersLookup(){
    return this.getLookup(LS.get('NLE'), LS.get('C'), LookupSource.USERS);

  }

  public changeStatus(id: string, active: boolean, onSuccess: Function) {
    this.requestService.createRequest(HttpRequestType.Patch, `${Constants.contextPath}/users/${id}/${active ? 'activate' : 'deactivate'}`, null, null, (response) => {
      onSuccess(response);
    }, (error) => {
      this.alertService.error(error, true);
    });
  }

  public getBusinessPartnerLookup() {
    return this.getOptionsByMultipleFieldsFilter(`${Constants.contextPath}/partners`, 'name', ['name'], (response) => {      
      return response;
    })
  }
}
