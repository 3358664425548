import { Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Observer, Subscription } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { LoadingService } from '../../core/loading.service';
import { UtilityService } from '../../core/utility.service';
import { DialogService, Dialog } from '../../core/dialog.service';
import { AlertService } from '../../core/alert.service';
import { TranslatorService } from '../../core/translator.service';
import { RouteAuthorities } from '../base/base.models';

import { BaseService } from './base.service';
import { DxFormComponent } from 'devextreme-angular';
import { RequestStatus } from 'src/app/core/request.service';

@Component({
    template: ''
})
export class BaseFormComponent implements OnDestroy {
    @ViewChild('form') form: DxFormComponent;

    public id: number | string = this.activatedRoute.snapshot.params['id'];
    public authorities: RouteAuthorities;
    public formData: any;
    protected initialFormData: any;
    public requestStatus: RequestStatus = new RequestStatus();
    public breadcrumbsId: string;
    public pendingChangesDialog = new Observable<boolean>((observer: Observer<boolean>) => {
        let dialog = new Dialog('SHARED.PENDING_CHANGES', () => {
            this.saveChanges();
            observer.next(false);
            observer.complete();
        }, () => {
            observer.next(true);
            observer.complete();
        });

        this.dialogService.show(dialog);
    });
    public url: string;
    public pendingChanges: boolean = false;
    protected initialValue: any;
    protected valueChangesSubscription: Subscription;
    public currentUserLegalEntityType: string = null;
    public active: boolean = true;
    public activeText: string = 'SHARED.ACTIVE';
    public innerWidth: number;

    constructor(
        public authService: AuthService,
        public loadingService: LoadingService,
        public translatorService: TranslatorService,
        public utilityService: UtilityService,
        protected activatedRoute: ActivatedRoute,
        protected baseService: BaseService,
        protected dialogService: DialogService,
        protected router: Router,
        protected alertService: AlertService

    ) {
        this.authorities = new RouteAuthorities(authService, alertService, null, null, null, null);
    }

    ngOnDestroy() {
        if (this.valueChangesSubscription)
            this.valueChangesSubscription.unsubscribe();
    }

    public saveChanges(): void {
        if (!this.isFormValid())
            return;

        if (this.id == null)
            this.baseService.create(this.url, this.formData, () => {
                this.onSaveSuccess();
            });
        else
            this.baseService.update(this.url, this.formData, () => {
                this.onSaveSuccess();
            });
    }

    public fieldDataChanged($event: any): void {
        if (!this.pendingChanges && this.initialFormData && this.initialFormData[$event.dataField] != $event.value)
            this.pendingChanges = true;
    }

    public itemsGridChanged(): void {
        if (!this.pendingChanges)
            this.pendingChanges = true;
    }

    @HostListener('window:beforeunload')
    public canDeactivate(): boolean {
        return !this.pendingChanges;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = event.target.innerWidth;
    }

    protected prepareFormData(initialValues: any = null, modifyResponse: Function = null): void {
        if (this.id == null && this.authorities.createToast)
            this.initializeForm(initialValues != null ? initialValues : {});
        else if (this.authorities.readToast)
            this.baseService.getById(this.url, this.id, (response: any) => {
                response = this.modifyDateCreatedAndModified(response);

                if (modifyResponse)
                    response = modifyResponse(response);

                this.breadcrumbsId = response.breadcrumbsId || response.name;
                this.initializeForm(response);
            }, () => {
                this.requestStatus.setFailed();
            });
    }

    modifyDateCreatedAndModified(response) {
        if (response.dateCreated)
            response.dateCreated = this.modifyDate(response.dateCreated);
        if (response.dateModified)
            response.dateModified = this.modifyDate(response.dateModified);
        return response;
    }

    protected initializeForm(formData: any): void {
        this.formData = formData;
        this.initialFormData = { ...formData };
        this.requestStatus.setSuccessful();
    }

    protected isFormValid(): boolean {
        if (this.form.instance.validate() != null && !this.form.instance.validate().isValid) {
            this.alertService.error('SHARED.INVALID_FORM');
            return false;
        }

        return true;
    }

    protected onSaveSuccess(): void {
        this.pendingChanges = false;
        this.alertService.success('SHARED.SAVE_SUCCESS');
        this.router.navigateByUrl(this.router.url.split('/')[1]);
    }

    public codeToUpper(): void {
        if (this.formData != null && this.formData.code != null)
            this.formData.code = this.formData.code.toUpperCase();
    }

    public setFocus(e: any) {
        setTimeout(() => {
            e.component.focus();
        }, 0);
    }

    public modifyDate(dateString: string) {
        return new Date(dateString + 'Z');
    }

    changeStatus() {
        let dialogData = new Dialog(this.active ? 'SHARED.DEACTIVATE_QUESTION' : 'SHARED.ACTIVATE_QUESTION', () => {
            this.baseService.changeActiveInForm(this.url, <string>this.id, this.active, (response) => {
                this.active = response.active;
                this.activeText = response.active ? 'SHARED.ACTIVE' : 'SHARED.INACTIVE';
                let modifiedResponse = this.modifyResponse(response);
                this.formData = modifiedResponse;
                this.initialFormData = { ...modifiedResponse };
                this.pendingChanges = false;
                this.alertService.success(response.active ? 'SHARED.ACTIVATE_SUCCESS' : 'SHARED.DEACTIVATE_SUCCESS');
            });
        });

        this.dialogService.show(dialogData);
    }

    modifyResponse(response: any) {
        return response;
    }
}
